/** 
 * Application Notification List Item
 */
.ui.feed.notification > .event {
  flex-direction: column !important;
  opacity: 0.7;
}
.ui.feed.notification > .event > .content {
  flex-direction: row;
}
.ui.feed.notification > .event > .content > .summary {
  font-weight: normal;
}
.ui.feed.notification > .event > .content > .date {
  float: right !important;
  margin: auto;
  margin-left: 1em;
}
.ui.feed.notification > .event > .extra {
  padding-bottom: 1em;
  white-space: break-spaces !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
}
.ui.feed.notification > .event > .extra.expanded {
  -webkit-line-clamp: 4 !important;
}
.ui.feed.notification > .event > .meta {
  height: 0em;
  transition: height 0.5s ease;
}
.ui.feed.notification > .event > .meta.expanded {
  height: 6em;
}
.selected ~ .content .ui.feed.notification > .event {
  opacity: 1;
}
.selected ~ .content .ui.feed.notification > .event > .content > .summary {
  font-weight: bold;
}
