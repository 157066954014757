.dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.dashboard ::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.dashboard-header .ui.transparent.input {
  width: auto;
}
.dashboard-header .ui.transparent.input > .icon {
  margin: auto;
  color: rgba(27, 28, 29, 0.3);
  opacity: 0;
  transition: opacity 250ms ease-in;
}
.dashboard-header .ui.transparent.input:hover > .icon {
  opacity: 1;
}
.dashboard-grid {
  height: calc(100% - 36px);
  display: flex;
}
.dashboard-grid .grid {
  flex-grow: 1;
  overflow: hidden;
  height: 100%;
}
.dashboard-grid .grid > .row:not(:only-child) {
  max-height: 50%;
  min-height: 50%;
}
.dashboard-grid-cell {
  height: 100% !important;
  width: 100% !important;
}
.dashboard-grid-cell.dragging > * {
  opacity: 0.5 !important;
  background-color: #f1fafe !important;
}
.dashboard-grid-cell.over > * {
  border-color: #109eda !important;
  background-color: #f1fafe !important;
}
.dashboard-widget {
  display: flex;
  flex-direction: column;
  height: 100% !important;
  width: 100% !important;
  padding: 0 !important;
  overflow: hidden;
}
.dashboard-widget > .ui.secondary.menu {
  border: none;
  box-shadow: none;
  margin-bottom: 0;
}
.dashboard-widget .actions {
  opacity: 0 !important;
}
.dashboard-widget:hover .actions {
  opacity: 1 !important;
}
.dashboard-widget.placeholder {
  border-style: dashed !important;
  border-width: 2px !important;
}
.dashboard-widget.placeholder:not(.tertiary) {
  box-shadow: none !important;
}
.dashboard-widget > .ui.container > article {
  flex-grow: 1;
}
.dashboard-widget > .ui.container > article > * {
  height: 100%;
  width: 100%;
}
.dashboard-bar {
  background: inherit !important;
  border: none !important;
}
.dashboard-bar-add {
  width: 260px !important;
}
.dashboard-bar-add .struct1 {
  cursor: auto;
  content: url('../../../assets/images/dashboard/struct1.svg');
  transform: scale(1);
  transition: transform 0.4s ease-in-out !important;
  backface-visibility: hidden;
}
.dashboard-bar-add .struct1:hover {
  transform: scale(1.2);
}
.dashboard-bar-add .struct2 {
  cursor: auto;
  content: url('../../../assets/images/dashboard/struct2.svg');
  transform: scale(1);
  transition: transform 0.4s ease-in-out !important;
  backface-visibility: hidden;
}
.dashboard-bar-add .struct2:hover {
  transform: scale(1.2);
}
.dashboard-bar-add .struct3 {
  cursor: auto;
  content: url('../../../assets/images/dashboard/struct3.svg');
  transform: scale(1);
  transition: transform 0.4s ease-in-out !important;
  backface-visibility: hidden;
}
.dashboard-bar-add .struct3:hover {
  transform: scale(1.2);
}
.dashboard-bar-add .struct4 {
  cursor: auto;
  content: url('../../../assets/images/dashboard/struct4.svg');
  transform: scale(1);
  transition: transform 0.4s ease-in-out !important;
  backface-visibility: hidden;
}
.dashboard-bar-add .struct4:hover {
  transform: scale(1.2);
}
.dashboard-bar-add .struct5 {
  cursor: auto;
  content: url('../../../assets/images/dashboard/struct5.svg');
  transform: scale(1);
  transition: transform 0.4s ease-in-out !important;
  backface-visibility: hidden;
}
.dashboard-bar-add .struct5:hover {
  transform: scale(1.2);
}
.dashboard-bar-add .struct6 {
  cursor: auto;
  content: url('../../../assets/images/dashboard/struct6.svg');
  transform: scale(1);
  transition: transform 0.4s ease-in-out !important;
  backface-visibility: hidden;
}
.dashboard-bar-add .struct6:hover {
  transform: scale(1.2);
}
.dashboard-bar-add .struct7 {
  cursor: auto;
  content: url('../../../assets/images/dashboard/struct7.svg');
  transform: scale(1);
  transition: transform 0.4s ease-in-out !important;
  backface-visibility: hidden;
}
.dashboard-bar-add .struct7:hover {
  transform: scale(1.2);
}
.dashboard-bar-add .struct8 {
  cursor: auto;
  content: url('../../../assets/images/dashboard/struct8.svg');
  transform: scale(1);
  transition: transform 0.4s ease-in-out !important;
  backface-visibility: hidden;
}
.dashboard-bar-add .struct8:hover {
  transform: scale(1.2);
}
.dashboard-bar-add .struct9 {
  cursor: auto;
  content: url('../../../assets/images/dashboard/struct9.svg');
  transform: scale(1);
  transition: transform 0.4s ease-in-out !important;
  backface-visibility: hidden;
}
.dashboard-bar-add .struct9:hover {
  transform: scale(1.2);
}
