/** 
 * Application User Avatar
 */
.application-user-avatar {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  vertical-align: middle;
  border-radius: 600rem;
  width: 32px;
  height: 32px;
}
.application-user-avatar span {
  position: absolute;
  box-sizing: border-box;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  line-height: 1;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  user-select: none;
  border-radius: inherit;
  background-color: rgba(255, 255, 255, 0.15);
}
