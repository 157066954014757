@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
.slick-list {
  height: 100%;
}
.slick-track,
.slick-slide,
.slick-slide > div,
.slick-slide > div > a {
  height: 100%;
}
.slick-slide a {
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.slick-slide a > span {
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
  height: auto;
  min-height: 48px;
  width: 100%;
  color: #ffffff;
  background: rgba(32, 32, 32, 0.5);
}
.slick-next {
  right: 0 !important;
  z-index: 1;
}
.slick-prev {
  left: 0 !important;
  z-index: 1;
}
.slick-next,
.slick-prev {
  height: 100%;
}
.slick-next:before,
.slick-prev:before {
  color: #1b1c1d;
}
