/** 
 * Application History Item
 */
.application-history-item .icon {
  position: relative;
  vertical-align: baseline !important;
  top: -2px;
}
.application-history-item span {
  padding-left: 1em;
}
.application-history-item.suppression span {
  text-decoration: line-through;
}
.application-history-item.suppression span {
  color: rgba(27, 28, 29, 0.3) !important;
}
.ui.feed.history {
  width: 14em;
  height: 5em;
}
.ui.feed.history > .event {
  height: 100%;
  flex-direction: column !important;
}
.ui.feed.history > .event > .content {
  flex-direction: row;
}
.ui.feed.history > .event > .content > .date {
  float: right !important;
  margin: auto;
  margin-left: 1em;
}
